import React, { ReactElement, ReactNode } from "react";
import { Routes, Route } from "react-router-dom";
import * as View from "../pages-sec/view";
import LoadingMine from "../components/loadingmine";

const RouteConfigSec = (): ReactElement<ReactNode> => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<LoadingMine />}>
            <View.IndexViewSec />
          </React.Suspense>
        }
      >
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingMine />}>
              <View.HomeViewSec />
            </React.Suspense>
          }
        ></Route>
      </Route>
    </Routes>
  );
};

export default RouteConfigSec;
